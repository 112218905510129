import * as dexlib from "@/assets/js/dexlib.js";
import Vue from "vue";

export default ({
    namespaced: true,
    state: {
        activeNodePathIntStringTry: null,
        activeNodePathIntString: "",
        openedNodePathIntString: "",
        openedNode: {
            type: null
        },
        targetObjectType: "",
        cardsPerLine: 3,
        windowEntryData: [],
        windowEntryIsSaved: true,
        windowEntryNotification: [],
        overlayWindowList: []
    },
    getters: {
        getActiveNodePathIntString: function (state) {
            return state.activeNodePathIntString;
        },
        getOpenedNodePathIntString: function (state) {
            return state.openedNodePathIntString;
        },
        getTargetObjectType: function (state) {
            return state.targetObjectType;
        },
        getActiveNodePathIntArray: (state) => (getter) => {
            var pathArr = new Array();
            var intArr = new Array();
            if (getter) intArr = getter.split("/");
            else intArr = state.activeNodePathIntString.split("/");
            for (var i = 0; i < intArr.length; i++) {
                if (intArr[i]) {
                    if (Number.isInteger(Number.parseInt(intArr[i]))) {
                        pathArr.push(Number.parseInt(intArr[i]));
                    } else {
                        pathArr.push(intArr[i]);
                    }
                }
            }
            return pathArr;
        },
        getCardsPerLine: function (state) {
            return state.cardsPerLine;
        },
        convertIntStringToIntArray: () => (pathIntString) => {
            var pathArr = new Array();
            var intArr = new Array();
            if (pathIntString) intArr = pathIntString.split("/");
            for (var i = 0; i < intArr.length; i++) {
                if (intArr[i]) {
                    if (Number.isInteger(Number.parseInt(intArr[i]))) {
                        pathArr.push(Number.parseInt(intArr[i]));
                    } else {
                        pathArr.push(intArr[i]);
                    }
                }
            }
            return pathArr;
        },
        getEntryData: function (state) {
            var o = new Object();
            for (var i = 0; i < state.windowEntryData.length; i++) {
                o[state.windowEntryData[i].key] = state.windowEntryData[i].value;
            }
            return o;
        },
        getFullyPopulatedEntryData: (state) => (requiredProps) => {
            var o = new Object();
            //for (var i = 0; i < state.windowEntryData.length; i++) {
            //    o[state.windowEntryData[i].key] = state.windowEntryData[i].value;
            //}
            // Populate with required props if not specified
            for (var j = 0; j < requiredProps.length; j++) {
                var v = state.windowEntryData.find(el => el.key == requiredProps[j].type);
                if (v == undefined) v = { value: null };
                o[requiredProps[j].type] = v.value;
            }
            return o;
        },
        getEntryDataKey: (state) => (getter) => {
            return state.windowEntryData.find(el => el.key == getter) ? state.windowEntryData.find(el => el.key == getter).value : null;
        },
        getWindowEntryIsSaved: function (state) {
            return state.windowEntryIsSaved;
        },
        getWindowEntryNotification: function (state) {
            return state.windowEntryNotification.length > 0 ? state.windowEntryNotification[state.windowEntryNotification.length - 1] : { type: null };
        },
        getOverlayWindow: function (state) {
            return state.overlayWindowList.length > 0 ? state.overlayWindowList[state.overlayWindowList.length - 1] : { type: null };
        },
        getOpenedNode: function (state) {
            return state.openedNode;
        }
    },
    mutations: {
        setActiveNodePath: function (state, payload) {
            if (!payload.path == undefined) throw "Payload is missing property, 'path'";
            var path = payload.path;
            if (payload.path == "last") {
                path = state.activeNodePathIntStringTry;
                state.activeNodePathIntStringTry = "";
            }
            switch (typeof (path)) {
                case "string":
                    state.activeNodePathIntString = path;
                    break;
                case "object":
                    if (Array.isArray(path)) state.activeNodePathIntString = path.join("/");
                    break;
            }
        },
        setOpenedNodePath: function (state, payload) {
            if (payload.path == undefined) throw "Payload is missing property, 'path'";
            var path = payload.path; 
            if (path == "close") path = "";
            switch (typeof (path)) {
                case "string":
                    state.openedNodePathIntString = path;
                    break;
                case "object":
                    if (Array.isArray(path)) state.openedNodePathIntString = path.join("/");
                    break;
            }
            if (payload.type) {
                Vue.set(state.openedNode, "type", payload.type);
            } else {
                Vue.set(state.openedNode, "type", null);
            }
        },
        setCardsPerLine: function (state, payload) {
            if (!payload.count) return;
            state.cardsPerLine = payload.count
        },
        setTargetObjectType: function (state, payload) {
            if (!payload.type) return;
            state.targetObjectType = payload.type;
        },
        setWindowEntryData: function (state, payload) {
            if (!payload.data) throw "Payload is missing parameter, 'data'";
            for (var i = 0; i < Object.keys(payload.data).length; i++) {
                if (typeof (payload.data[Object.keys(payload.data)[i]]) != "object") {
                    state.windowEntryData.push({ key: Object.keys(payload.data)[i], value: payload.data[Object.keys(payload.data)[i]] });
                }
            }
        },
        setWindowEntryDataKey: function (state, payload) {
            if (!payload.key) return;
            var o = state.windowEntryData.find(el => el.key == payload.key);
            if (o) {
                if (!payload.value) {
                     state.windowEntryData.splice(state.windowEntryData.indexOf(o), 1);
                } else {
                    o.value = payload.value;
                }
            } else {
                state.windowEntryData.push({ key: payload.key, value: payload.value });
            }
        },
        emptyWindowEntryData: function (state) {
            state.windowEntryData = new Array();
        },
        setWindowEntryIsSaved: function (state, payload) {
            if (payload.status == undefined) throw "Payload is missing parameter, 'status'";
            state.windowEntryIsSaved = payload.status;
        },
        setWindowNotification: function (state, payload) {
            if (payload.type == undefined) throw "Payload is missing parameter, 'type'";
            state.windowEntryNotification.push({
                type: payload.type,
                caller: payload.caller
            });
        },
        deleteWindowNotification: function (state, payload) {
            if (payload.type == undefined) throw "Payload is missing parameter, 'type'";
            var i = state.windowEntryNotification.findIndex(e => e.type == payload.type);
            if (i > -1) state.windowEntryNotification.splice(i, 1);
        },
        setOverlayWindowItem: function (state, payload) {
            if (payload.type == undefined) throw "Payload is missing parameter, 'type'";
            state.overlayWindowList.push({
                type: payload.type,
                caller: payload.caller
            });
        },
        deleteOverlayWindowItem: function (state, payload) {
            if (payload.type == undefined) throw "Payload is missing parameter, 'type'";
            var i = state.overlayWindowList.findIndex(e => e.type == payload.type);
            if (i > -1) state.overlayWindowList.splice(i, 1);
        },
    },
    actions: {
        replaceActiveNodePath: function (context, payload) {
            try {
                if (!context.getters.getWindowEntryIsSaved) {
                    context.dispatch("replaceWindowNotification",{
                        status: true,
                        type: "unsavedChanges",
                        caller: "replaceActive"
                    });
                    context.state.activeNodePathIntStringTry = payload.path;
                    throw "There are unsaved changes!";
                }
                context.commit("setActiveNodePath", payload);
                context.commit("setTargetObjectType", payload);
                context.commit("setOpenedNodePath", { path: "close" });
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        replaceOpenedNodePath: function (context, payload) {
            try {
                if (!context.getters.getWindowEntryIsSaved) {
                    context.dispatch("replaceWindowNotification", {
                        status: true,
                        type: "unsavedChanges",
                        caller: "replaceOpened"
                    });
                    throw "There are unsaved changes!";
                }
                context.commit("setOpenedNodePath", payload);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        replaceCardsPerLine: function (context, payload) {
            try {
                context.commit("setCardsPerLine", payload);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        replaceEntryData: function (context, payload) {
            try {
                context.commit("emptyWindowEntryData");
                context.commit("setWindowEntryData", payload);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        replaceEntryDataKey: function (context, payload) {
            try {
                context.commit("setWindowEntryDataKey", payload);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        replaceWindowEntryIsSaved: function (context, payload) {
            try {
                context.commit("setWindowEntryIsSaved", payload);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        replaceWindowNotification: function (context, payload) {
            try {
                context.commit("deleteWindowNotification", payload);
                context.commit("setWindowNotification", payload);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        removeWindowNotification: function (context, payload) {
            try {
                context.commit("deleteWindowNotification", payload);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        clearAllWindowNotifications: function (context) {
            try {
                Vue.set(context.state, "windowEntryNotification", []);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        replaceOverlayWindowItem: function (context, payload) {
            try {
                switch (payload.type) {
                    case "download":
                    case "upload":
                    case "reset":
                        if(!context.getters.getWindowEntryIsSaved) {
                            context.dispatch("replaceWindowNotification", {
                                status: true,
                                type: "unsavedChanges",
                                caller: "tryDownload"
                            });
                            throw "There are unsaved changes!";
                        } else {
                            context.dispatch("replaceOpenedNodePath", { path: "close" });
                        }
                }
                context.dispatch("clearAllOverlayWindowItems");
                //context.commit("deleteOverlayWindowItem", payload);
                context.commit("setOverlayWindowItem", payload);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        removeOverlayWindowItem: function (context, payload) {
            try {
                context.commit("deleteOverlayWindowItem", payload);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        clearAllOverlayWindowItems: function (context) {
            try {
                Vue.set(context.state, "overlayWindowList", []);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        }
    }
})