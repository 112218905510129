<template>
    <div>
        <div>          
            <div>
                <div><h4>Device Type</h4></div>
                <br />
                <select v-model="selectedDeviceType" v-on:change="onBuildingChnage($event)">
                    <option v-for="(device) in deviceTypeList" :key="device.id" :value=device>{{device.displayName}} (ID:{{device.id}})</option>
                </select>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios"
    import { mapGetters, mapActions } from "vuex"
    export default {
        name: "DeviceTypeSelector", 
        data() {
            return {
              
            }
        },
        components: {
            
        },
        emits: ['connectionChangedEvent', 'buildingChangedEvent'],
        computed: {
            selectedDeviceType: {
                get() {
                    return this.$store.state.dit.selectedDeviceType
                },
                set(value) {
                    this.$store.commit('dit/setSelectedDeviceType', value)
                }
            },
            deviceTypeList: {
                get() {
                    return this.$store.state.dit.deviceTypeList
                },
                set(value) {
                    this.$store.commit('dit/setDeviceTypeList', value)
                }
            },
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile",
                hasClientRoles: "hasClientRoles"
            })
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            LoadDeviceTypes() {
                this.replaceLoader({ loader: true });
                return axios.get("/api/dit/GetDeviceTypes").then(res => {
                        if (!res.data.status) {
                            throw res.data.message[0];
                    }
                    this.deviceTypeList = res.data.data;
                    }).catch(e => {
                        alert(e.message);
                    }).finally(() => {
                        this.replaceLoader({ loader: false });
                    });
            },
            init: function () {
                if (!this.getProfile) return;
                if (this.deviceTypeList === null) {
                    this.LoadDeviceTypes()
                }
            }
        },
        watch: {
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
</style>