<template>
    <div class="pad-child" align="center">
        <div v-if="hasClientRoles(['ditinstallation'])">
            <h2>Installation</h2>
            <LocationSelector :level="3" ref="childRef" @floorChangedEvent="click" />
            <div>
                <div>
                    <br />
                    <div>
                        <div><h4>Installation Search</h4></div>
                        <input v-model=installationsearchstring /> <br />
                        <div><label>Installations  (found: {{plannedInstallationDataInstallbleFiltered.length}})</label></div>
                        <select v-model="selectedInstallation">
                            <option v-for="(installation,k) in plannedInstallationDataInstallbleFiltered" :key="k" :value=installation>{{installation.assetName}}</option>
                        </select>
                    </div>
                    <br />
                    <div>
                        <h4>Device Search</h4>
                        <label v-if="selectedInstallation != null">DeviceType:  {{selectedInstallation.deviceTypeName}}</label>
                        <input v-model=devicesearchstring /> <button @click="searchdevice" class="box">search</button>
                        <div>
                            <div><label>Device  (found: {{devicecount}})</label></div>
                            <select v-model="selectedDevice">
                                <option v-for="(device,k) in deviceList" :key="k" :value=device>{{device.deviceUid}}</option>
                            </select>
                        </div>
                    </div>
                    <br />
                    <div>
                        <br />
                        <button @click="install" class="box">install</button>
                        <br />
                    </div>
                    <br />
                    <div>
                        <div v-for="(result,k) in installationresult" :key="k">
                            <label>{{result}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <h2>401 Unauthorized</h2>
        </div>
    </div>
</template>

<script>
    //import axios from "axios"
    import axios from "axios"
    import { mapGetters, mapActions } from "vuex"
    import LocationSelector from '../components/dit/LocationSelector.vue'
    export default {
        name: "DITInstallation",
        data() {
            return {
                selectedInstallation: null,
                selectedDevice: null,
                assetlist: null,
                deviceList: null,
                devicesearchstring: null,
                installationresult: [],
                installationsearchstring: null,

            }
        },
        components: {
            LocationSelector
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile",
                hasClientRoles: "hasClientRoles"
            }),
            devicecount:
            {
                get() {
                    if (this.deviceList != null) {
                        return this.deviceList.length;
                    } else {
                        return 0
                    }
                }
            },
            plannedInstallationData: {
                get() {
                    return this.$store.state.dit.plannedInstallationData
                },
                set(value) {
                    console.log(value);
                    this.$store.commit('dit/setPlannedInstallationData', value)
                }
            },
            selectedInstallableFloor: {
                get() {
                    return this.$store.state.dit.selectedInstallableFloor
                }
            },
            plannedInstallationDataInstallbleFiltered: {
                get() {
                    if (this.plannedInstallationData != null) {
                        if (this.installationsearchstring === null || this.installationsearchstring === '') {
                            return this.plannedInstallationData.filter(obj => {
                                return obj.installedDate === null && obj.floorName == this.selectedInstallableFloor.name
                            });
                        } else {
                            return this.plannedInstallationData.filter(obj => {
                                return obj.assetName.toLowerCase().includes(this.installationsearchstring.toLowerCase()) && obj.installedDate === null && obj.floorName === this.selectedInstallableFloor.name
                            });
                        }
                    }
                    else {
                        return [];
                    }
                }
            }
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            LoadBuildings() {
                this.replaceLoader({ loader: true });
                return axios.get("/api/dit/GetInstallationsBuildingList",
                    {
                        headers: {
                            'ConnectionName': this.$store.state.connection.connectionstring
                        }
                    }).then(res => {
                        if (!res.data.status) {
                            throw res.data.message[0];
                        }
                        this.assetlist = res.data.data;
                    }).catch(e => {
                        alert(e.message);
                    }).finally(() => {
                        this.replaceLoader({ loader: false });
                    });
            },
            click() {
                this.$refs.childRef.LoadPlannedInstallationData();
            },
            searchdevice() {
                this.deviceList = null;
                this.replaceLoader({ loader: true });
                return axios.get("/api/dit/FindDevices",
                    {
                        params: { SearchString: this.devicesearchstring },
                        headers: {
                            'ConnectionName': this.$store.state.connection.connectionstring,
                            'DeviceType': this.selectedInstallation.deviceTypeID,
                            'SearchString': this.devicesearchstring,
                            'mappedStatus': 'NotMapped'
                        }
                    }).then(res => {
                        if (!res.data.status) {
                            throw res.data.message[0];
                        }
                        console.log(res.data.data)
                        this.deviceList = res.data.data;
                    }).catch(e => {
                        alert(e.message);
                    }).finally(() => {
                        this.replaceLoader({ loader: false });
                    });
            },
            install() {
                if (this.selectedDevice != null && this.selectedInstallation != null) {
                    this.replaceLoader({ loader: true });
                    return axios.post("/api/dit/MapDeviceToAsset", "",
                        {
                            headers: {
                                'ConnectionName': this.$store.state.connection.connectionstring,
                                'DeviceID': this.selectedDevice.id,
                                'DeviceEUI': this.selectedDevice.deviceUid,
                                'AssetID': this.selectedInstallation.assetID,
                                'InstallationID': this.selectedInstallation.id

                            }
                        }).then(res => {
                            if (!res.data.status) {
                                throw res.data.message[0];
                            }
                            switch (res.data.data) {
                                case "-1":
                                    this.installationresult.push(`${this.selectedDevice.deviceUid} mapping to ${this.selectedInstallation.assetName} failed`);
                                    break;
                                case "1":
                                    this.installationresult.push(`${this.selectedDevice.deviceUid} mapping to ${this.selectedInstallation.assetName} was sucessful`);
                                    break;
                                case "2":
                                    this.installationresult.push(`${this.selectedDevice.deviceUid} mapping to ${this.selectedInstallation.assetName} already existed`);
                                    break;
                                default:
                                    break;
                            }
                        }).catch(e => {
                            alert(e.message);
                        }).finally(() => {
                            this.$refs.childRef.LoadPlannedInstallationData();
                            this.selectedDevice = null;
                            this.deviceList = null;
                            this.devicesearchstring = null;
                            this.installationsearchstring = null;
                            this.replaceLoader({ loader: false });

                        });
                }

            },
            init: function () {
                if (!this.getProfile) return;
            }
        },
        watch: {
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
</style>