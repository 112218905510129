<template>
    <div class="pad-child" align="center">
        <div v-if="hasClientRoles(['ditinstallation'])">
            <h2>Overview</h2>
            <!--<LocationSelector @buildingChangedEvent="LoadData" ref="childRef" />-->
            <LocationSelector :level="2" ref="childRef" @buildingChangedEvent="click"/>
            <div>
                <br />
                <button @click="click" class="box">Load</button>
            </div>
            <br />
            <div>
                <div> installed <input type="checkbox" id="showninstalledcheckbox" v-model="showinstalled" /> not installed <input type="checkbox" id="shownotinstalledcheckbox" v-model="shownotinstalled" /></div>
                <br />
                <div><label>installations seleceted: {{plannedInstallationDatafiltered.length}}</label></div>
            </div>
            <br />
            <div class="table-wrapper">
                <table style="height: 50vh;">
                    <thead>
                        <tr>
                            <th>Building</th>
                            <th @click="sort('floorName')"><nobr>Floor<span v-if="currentSort == 'floorName'"><span v-if="currentSortDir == 'asc'">&nbsp;↑</span><span v-else>&nbsp;↓</span></span></nobr></th>
                            <th @click="sort('assetName')"><nobr>Asset<span v-if="currentSort == 'assetName'"><span v-if="currentSortDir == 'asc'">&nbsp;↑</span><span v-else>&nbsp;↓</span></span></nobr></th>
                            <th @click="sort('deviceTypeName')"><nobr>DeviceType<span v-if="currentSort == 'deviceTypeName'"><span v-if="currentSortDir == 'asc'">&nbsp;↑</span><span v-else>&nbsp;↓</span></span></nobr></th>
                            <th @click="sort('deviceEUI')"><nobr>DeviceEUI<span v-if="currentSort == 'deviceEUI'"><span v-if="currentSortDir == 'asc'">&nbsp;↑</span><span v-else>&nbsp;↓</span></span></nobr></th>
                            <th @click="sort('createdDate')"><nobr>Created<span v-if="currentSort == 'createdDate'"><span v-if="currentSortDir == 'asc'">&nbsp;↑</span><span v-else>&nbsp;↓</span></span></nobr></th>
                            <th @click="sort('installedDate')"><nobr>Installed<span v-if="currentSort == 'installedDate'"><span v-if="currentSortDir == 'asc'">&nbsp;↑</span><span v-else>&nbsp;↓</span></span></nobr></th>
                            <th v-if="hasClientRoles(['ditplanner'])" >Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="installationdata in plannedInstallationDatafiltered" :key="installationdata.id">
                            <td><center>{{installationdata.buildingName}}</center></td>
                            <td><center>{{installationdata.floorName}}</center></td>
                            <td><center>{{installationdata.spaceName}}</center></td>
                            <!--<td><center>{{installationdata.deviceTypeID}}</center></td>-->
                            <td><center>{{installationdata.deviceTypeName}}</center></td>
                            <td><center>{{installationdata.deviceEUI}}</center></td>
                            <td><center>{{new Date(installationdata.createdDate).toISOString().replace('T',' ').split('.')[0]}}</center></td>
                            <td v-if='installationdata.installedDate != null'><center>{{new Date(installationdata.installedDate).toISOString().replace('T',' ').split('.')[0]}}</center></td>
                            <td v-else><center> </center></td>
                            <td  v-if="installationdata.installedDate == null && hasClientRoles(['ditplanner'])"><center><button @click="deleteInstallation(installationdata)" class="box">delete</button></center></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else>
            <h2>401 Unauthorized</h2>
        </div>
    </div>
</template>

<script>
    //import axios from "axios"
    import { mapGetters, mapActions } from "vuex"
    import axios from "axios"
    import LocationSelector from '../components/dit/LocationSelector.vue'
    export default {
        name: "DITOverview",
        data() {
            return {
                showinstalled : true,
                shownotinstalled: true,
                currentSort: 'buildingName',
                currentSortDir: 'asc'
            }
        },
        components: {
            LocationSelector
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile",
                hasClientRoles: "hasClientRoles"
            }),
            plannedInstallationDatafiltered: {
                get() {
                    var returndata = null;
                    switch (true) {
                        case (this.plannedInstallationData === null):
                            returndata = [];
                            break;
                        /*show both*/   
                        case (this.plannedInstallationData != null && this.showinstalled === true && this.shownotinstalled === true):
                            returndata = this.plannedInstallationData;
                            break;
                        /*just installed*/
                        case (this.plannedInstallationData != null && this.showinstalled === true && this.shownotinstalled === false):
                            returndata = this.plannedInstallationData.filter(obj => {
                                return obj.installedDate !== null
                            })
                            break;
                        /*just not installed*/
                        case (this.plannedInstallationData != null && this.showinstalled === false && this.shownotinstalled === true):
                            returndata = this.plannedInstallationData.filter(obj => {
                                return obj.installedDate === null
                            })
                            break;
                        default:
                            returndata = [];
                            break;
                    }
                    return returndata.sort((a, b) => {
                        let modifier = 1;
                        if (this.currentSortDir === 'desc') modifier = -1;
                        if (b[this.currentSort] === null) return -1 * modifier;
                        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                        return 0;
                    });
                }
            },
            plannedInstallationData: {
                get() {
                    return this.$store.state.dit.plannedInstallationData
                },
                set(value) {
                    this.$store.commit('dit/setPlannedInstallationData', value)
                }
            }
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            sort: function (s) {
                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
                }
                this.currentSort = s;
            },
            click() {
                this.$refs.childRef.LoadPlannedInstallationData();
            },
            deleteInstallation(installationdata) {
                var index = this.plannedInstallationData.indexOf(installationdata);
                if (index !== -1) {
                    this.replaceLoader({ loader: true });
                    return axios.delete("/api/dit/deleteplannedinstallations",
                        {
                            headers: {
                                'ConnectionName': this.$store.state.connection.connectionstring,
                                'InstallationID': installationdata.id
                            }
                        }).then(res => {
                            if (!res.data.status) {
                                throw res.data.message[0];
                            }
                            this.plannedInstallationData.splice(index, 1);
                        }).catch(e => {
                            alert(e.message);
                        }).finally(() => {
                            this.replaceLoader({ loader: false });
                        });

                }
            },
            init: function () {
                if (!this.getProfile) return;
            }
        },
        watch: {
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
</style>