<template>
    <div class="pad-child" align="center">
        <div v-if="hasClientRoles(['ditplanner'])">
            <h2>Asset Mapping</h2>
            <PlanningSelector :level="2" ref="childRef" @buildingChangedEvent="click" />
            <div>
                <br />
                <button @click="click" class="box">Load</button>
            </div>
            <DeviceAssetMappingEdit v-if="popup.name == 'object'" @closePopup="closePopup" @reload="reloadHandler" :mappingData="popup.data"></DeviceAssetMappingEdit>

            <div class="table-wrapper">
                <table style="height: 50vh;">
                    <thead>
                        <tr>
                            <th>devicesDeviceUid</th>
                            <th>devicesDisplayName</th>
                            <th>buildingName</th>
                            <th>floorName</th>
                            <th>assetName</th>
                            <th>edit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="mapping in mappings" :key="mapping.devicesId">
                            <td><center>{{mapping.devicesDeviceUid}}</center></td>
                            <td><center>{{mapping.devicesDisplayName}}</center></td>
                            <td><center>{{mapping.buildingName}}</center></td>
                            <td><center>{{mapping.floorName}}</center></td>
                            <td><center>{{mapping.assetName}}</center></td>
                            <td><center><button @click="editMapping(mapping)" class="box">edit</button></center></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div v-else>
            <h2>401 Unauthorized</h2>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex"
    import PlanningSelector from '../components/dit/PlanningSelector.vue'
    import DeviceAssetMappingEdit from '@/views/popup/DeviceAssetMappingEdit.vue'
    import axios from "axios"
    export default {
        name: "DITAssetMapping",
        data() {
            return {
                mappings: [],
                popup: {
                    name: null,
                    data: null
                },

            }
        },
        components: {
            PlanningSelector,
            DeviceAssetMappingEdit,
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile",
                hasClientRoles: "hasClientRoles"
            })

        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            init: function () {
                if (!this.getProfile) return;
            },
            closePopup: function () {
                this.popup = { name: null, data: null };
            },
            editMapping(mapping) {
                this.popup = { name: 'object', data: mapping };
            },
            reloadHandler: function () {
                console.log("");
            },
            click() {
                this.GetDeviceAssetMapping();
            },
            GetDeviceAssetMapping() {
                this.mappings = null;
                this.replaceLoader({ loader: true });
                return axios.get("/api/dit/GetDeviceAssetMappings",
                    {
                        params: { SearchString: this.searchstring },
                        headers: {
                            'ConnectionName': this.$store.state.connection.connectionstring,
                            'BuildingID': this.$refs.childRef.selectedPlanningBuilding.id
                        }
                    }).then(res => {
                        if (!res.data.status) {
                            throw res.data.message[0];
                        }
                        this.mappings = res.data.data;
                    }).catch(e => {
                        alert(e.message);
                    }).finally(() => {
                        this.replaceLoader({ loader: false });
                    });
            },
        },
        watch: {
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
</style>