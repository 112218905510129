<template>
    <div>
        <input id="collapsible" class="toggle" type="checkbox"> <label for="collapsible" class="lbl-toggle bg-azure-blue box">Spatial Selector</label>
        <div class="collapsible-content">
            <div>
                <ConnectionSelector @connectionChangedEvent="onConnectionNameChange()" />
                <div v-if="level >=2">
                    <div><label>Building</label></div>
                    <select v-model="selectedInstallableBuilding" v-on:change="onBuildingChnage($event)">
                        <option v-for="(building) in installebleBuildingList" :key="building.id" :value=building>{{building.name}}</option>
                    </select>
                </div>
                <div v-if="level>=3">
                    <div><label>Floor</label></div>
                    <select v-model="selectedInstallableFloor" v-on:change="onFloorChnage($event)">
                        <option v-for="(floor) in installebleFloorList" :key="floor.id" :value=floor>{{floor.name}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import { mapGetters, mapActions } from "vuex"
    import ConnectionSelector from '../default/ConnectionSelector.vue'

    export default {
        name: "PlaningSelector",
        props: {
            level: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {

            }
        },
        components: {
            ConnectionSelector
        },
        emits: ['connectionChangedEvent', 'buildingChangedEvent'],
        computed: {

            selectedInstallableBuilding: {
                get() {
                    return this.$store.state.dit.selectedInstallableBuilding
                },
                set(value) {
                    this.$store.commit('dit/setSelectedInstallableBuilding', value)
                }
            },
            installebleBuildingList: {
                get() {
                    return this.$store.state.dit.installebleBuildingList
                },
                set(value) {
                    this.$store.commit('dit/setInstallebleBuildingList', value)
                }
            },
            selectedInstallableFloor: {
                get() {
                    return this.$store.state.dit.selectedInstallableFloor
                },
                set(value) {
                    this.$store.commit('dit/setSelectedInstallableFloor', value)
                }
            },
            installebleFloorList: {
                get() {
                    return this.$store.state.dit.installebleFloorList
                },
                set(value) {
                    this.$store.commit('dit/setInstallebleFloorList', value)
                }
            },
            plannedInstallationData: {
                get() {
                    return this.$store.state.dit.plannedInstallationData
                },
                set(value) {
                    this.$store.commit('dit/setPlannedInstallationData', value)
                }
            },
            connection: {
                get() {
                    return this.$store.state.connection.connectionstring
                }
            },
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile",
                hasClientRoles: "hasClientRoles"
            })
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            LoadBuildings() {
                if (this.connection !== null && this.connection !== "") {

                    this.replaceLoader({ loader: true });
                    return axios.get("/api/dit/GetInstallationsBuildingList",
                        {
                            headers: {
                                'ConnectionName': this.connection
                            }
                        }).then(res => {
                            if (!res.data.status) {
                                throw res.data.message[0];
                            }
                            this.installebleBuildingList = res.data.data;
                        }).catch(e => {
                            alert(e.message);
                        }).finally(() => {
                            this.replaceLoader({ loader: false });
                        });
                }
            },
            LoadFloors() {
                this.replaceLoader({ loader: true });
                return axios.get("/api/dit/GetInstallationsFloorList",
                    {
                        headers: {
                            'ConnectionName': this.connection,
                            'BuildingID': this.selectedInstallableBuilding.id
                        }
                    }).then(res => {
                        if (!res.data.status) {
                            throw res.data.message[0];
                        }
                        this.installebleFloorList = res.data.data;
                    }).catch(e => {
                        alert(e.message);
                    }).finally(() => {
                        this.replaceLoader({ loader: false });
                    });
            },
            onBuildingChnage() {
                if (!this.getProfile) return;
                this.installebleFloorList = null;
                this.selectedInstallableFloor = null;
                this.LoadFloors();
                this.$emit('buildingChangedEvent', this.selectedInstallableBuilding);
                return true;
            },
            onFloorChnage() {
                if (!this.getProfile) return;
                this.$emit('floorChangedEvent', this.selectedInstallableBuilding);
                return true;
            },
            onConnectionNameChange() {
                this.selectedInstallableBuilding = null;
                this.LoadBuildings();
                this.$emit('connectionChangedEvent');
                return true;
            },

            LoadPlannedInstallationData() {
                if (this.selectedInstallableBuilding != null) {
                    this.replaceLoader({ loader: true });
                    return axios.get("/api/dit/getallplannedInstallations",
                        {
                            headers: {
                                'ConnectionName': this.connection,
                                'BuildingID': this.selectedInstallableBuilding.id
                            }
                        }).then(res => {
                            if (!res.data.status) {
                                throw res.data.message[0];
                            }
                            this.plannedInstallationData = res.data.data;
                        }).catch(e => {
                            alert(e.message);
                        }).finally(() => {
                            this.replaceLoader({ loader: false });
                        });
                }
            },
            init: function () {
                if (!this.getProfile) return;
                if (this.connection !== null && this.connection !== "") {
                    console.log(this.connection);
                    this.onConnectionNameChange()
                }
            }
        },
        watch: {
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    input[type="checkbox"] {
        display: none;
    }

    .lbl-toggle {
        min-width: calc(10 * var(--smaller));
        max-width: calc(13 * var(--smaller));
        text-align: center;
        cursor: pointer;
        transition: all 0.25s ease-out;
        background: var(--azure-blue);
        border-radius: 0;
        border: 1px solid var(--azure-blue);
        color: var(--stay-white);
        padding: calc(1.25 * var(--margin-025)) calc(0.75 * var(--margin-1));
    }

        .lbl-toggle:hover {
            color: #fff;
        }

        .lbl-toggle::before {
            content: " ";
            display: inline-block;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid currentColor;
            vertical-align: middle;
            margin-right: 0.7rem;
            transform: translateY(-2px);
            transition: transform 0.2s ease-out;
        }

    .toggle:checked + .lbl-toggle::before {
        transform: rotate(90deg) translateX(-3px);
    }

    .collapsible-content {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.25s ease-in-out;
    }

    .toggle:checked + .lbl-toggle + .collapsible-content {
        max-height: 350px;
    }

    .toggle:checked + .lbl-toggle {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .collapsible-content p {
        margin-bottom: 0;
    }
</style>