module.exports = {
    /**
     * Prints string in paramater using the console.log function.
     * @param {string} str String to be printed.
     */
    vuelog: function(str) {
        if (process.env.NODE_ENV == "development") {
            console.log(`LOG: ${str}`);
        }
    },
    /**
     * Prints string in paramater using the console.warn function.
     * @param {string} str String to be printed.
     */
    vuewarn: function(str) {
        if (process.env.NODE_ENV == "development") {
            console.warn(`WARN: ${str}`);
        }
    },
    /**
     * Gets a parameter from 1) URL, 2) Local Storage, if they are defined.
     * @param {string} key Key.
     * @param {bool} doNotStoreInLocalStorage Explicit flag not to store key-value pair in local storage. Key-value pair is stored in local storage by default. 
     */
    getParam: function(key, doNotStoreInLocalStorage) {
        // Search the local storage.
        var value = window.localStorage.getItem(key);
        // Override with value from URL as needed.
        const queryString = window.location.href.slice(window.location.href.indexOf("?"));
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has(key)) {
            value = urlParams.get(key);
            // If it is not in local storage, we store it by default unless stated otherwise
            if (!doNotStoreInLocalStorage) {
                this.setParam(key, value);
            }
        }
        return value;
    },
    /**
     * Sets a parameter in 1) Local Storage (always), 2) URL (if defined).
     * @param {string} key Key.
     * @param {string} value Value.
     * @param {boolean} doNotStoreInLocalStorage Explicit flag not to store key-value pair in local storage. Key-value pair is stored in local storage by default. 
     */
    setParam: function(key, value, doNotStoreInLocalStorage) {
        var queryString = window.location.search;
        if (!queryString && window.location.search.indexOf("?") < 0) {
            // We only replace the value in URL, when Vue Router is not involved.
            // We can detect this by comparing the value of query string to the presence of the '?' in href.
            const urlParams = new URLSearchParams(queryString);
            // Replace value in URL.
            if (urlParams.has(key)) {
                urlParams.set(key, value);
                window.history.pushState(null, null, `?${urlParams.toString()}`);
            }
        }
        // tldr; yes we store to local storage by default.
        if (!doNotStoreInLocalStorage) {
            window.localStorage.setItem(key, value);
        }
        return value;
    },
    capitalize: function (str) {
        return str ? str.slice(0, 1).toUpperCase() + str.slice(1) : str;
    }
}