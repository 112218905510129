<template>
  <li>
    <ul class="nav-section">
      <slot
        >
        <!--This component expects one or more <kbd>DexHeaderNavItem</kbd> and/or
        <kbd>DexHeaderNavItemExpandable</kbd> and/or
        <kbd>DexHeaderUserItemExpandable</kbd> components in this slot.-->
      </slot
      >
    </ul>
  </li>
</template>

<script>
export default {
  name: "DexHeaderSection",
};
</script>

<style scoped>
.nav-section {
  display: flex;
}

.collapsed .nav > ul,
.collapsed .nav-section {
  display: block;
  width: 100%;
}
</style>