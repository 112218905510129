import Vue from 'vue'
import Vuex from 'vuex'
import loaderModule from './default/loader.js'
import languageModule from './default/language.js'
import keycloakModule from './default/keycloak.js'
import connectionModule from './default/connection.js'
import ditModule from './default/dit.js'
import componentRulesModule from './creator-tool/component-rules.js'
import jsonStateModule from './creator-tool/json-state.js'
import editorStateModule from './creator-tool/editor-state.js'
import applicationStore from './creator-tool/application-store.js'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        keycloak: keycloakModule,
        lang: languageModule,
        loader: loaderModule,
        connection: connectionModule,
        dit: ditModule,
        jsonState: jsonStateModule,
        componentRules: componentRulesModule,
        editorState: editorStateModule,
        applicationStore: applicationStore
    }
})