<template>
  <li
    class="user-item"
    :class="initials ? 'initials' : ''"
    ref="userItem"
    @click="expandDropdown"
  >
    <a>
      <span v-if="initials" class="user-initials" v-html="initials"></span>
      <span v-html="initials ? 'Account Settings' : (itemLabel ? itemLabel : 'Please add <kbd>:itemLabel</kbd> or <kbd>:initials</kbd>')"></span>
      <i class="dexcon-arrow-down"></i>
    </a>
    <div class="dropdown" ref="dropdown">
      <button class="back" @click="shrinkDropdown">
        <i class="dexcon-chevron-left-thin"></i><span>{{ getText("back") }}</span>
      </button>
      <slot>
          This component expects one or more
          <kbd>DexHeaderDropdownGroup</kbd> components in the slot.
      </slot>
    </div>
  </li>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DexHeaderuserItemExpandable",
  props: ["itemLabel", "initials"],
  computed: {
    ...mapGetters("lang", {
      getText: "getText"
    })
  },
  methods: {
    shrinkDropdown: function (ev) {
      ev.stopPropagation();
      this.$refs.userItem.classList.remove("expanded");
    },
    expandDropdown: function () {
      this.$refs.userItem.classList.add("expanded");
    },
    toggleDropdown: function () {
      if (this.$refs.userItem.classList.contains("expanded")) {
        this.shrinkDropdown();
      } else {
        this.expandDropdown();
      }
    },
    shiftOverflowingDropdownIn: function () {
      // Important: please reset the left value on mouse out
      if (
        this.$refs.dropdown.getBoundingClientRect().left <
        document
          .querySelectorAll("header>ul>li:first-child")[0]
          .getBoundingClientRect().left
      ) {
        this.$refs.dropdown.style.setProperty(
          "left",
          `calc(50% + ${
            document
              .querySelectorAll("header>ul>li:first-child")[0]
              .getBoundingClientRect().left -
            this.$refs.dropdown.getBoundingClientRect().left
          }px)`
        );
      } else if (
        this.$refs.dropdown.getBoundingClientRect().right >
        document
          .querySelectorAll("header>ul>li:last-child")[0]
          .getBoundingClientRect().right
      ) {
        this.$refs.dropdown.style.setProperty(
          "left",
          `calc(50% - ${
            this.$refs.dropdown.getBoundingClientRect().right -
            document
              .querySelectorAll("header>ul>li:last-child")[0]
              .getBoundingClientRect().right
          }px)`
        );
      }
    }
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.$refs.dropdown.classList.remove("expanded");
    });
    this.$refs.userItem.addEventListener("mouseover", () => {
      if (document.querySelector("header.collapsed")) return;
      this.$refs.userItem.classList.add("expanded");
      this.shiftOverflowingDropdownIn();
    });
    this.$refs.userItem.addEventListener("mouseout", () => {
      if (document.querySelector("header.collapsed")) return;
      this.$refs.userItem.classList.remove("expanded");
      this.$refs.dropdown.style.setProperty("left", "50%");
    });
    if (this.initials) {
      this.$refs.userItem.parentNode.classList.add("has-initials");
    }
  },
};
</script>

<style scoped>
.dexcon-arrow-down {
  line-height: 95%;
}

.dexcon-arrow-down::before {
  content: none;
}

.user-item .dropdown {
  width: 20rem;
}

.user-item.initials .user-initials + span {
  display: none;
}
</style>

<style>
header.collapsed .user-item.initials .dropdown {
  top: 0;
  width: 100%;
}

header .user-item .group-item {
  width: calc(100% - var(--margin-025));
}

header.collapsed .user-item .dexcon-arrow-down {
  float: right;
}

header.collapsed .user-item .dexcon-arrow-down::before {
  content: "\e91c";
}

header.collapsed .user-item::after {
  content: none;
}

header.collapsed .user-initials {
  margin-right: var(--margin-05);
}

header.collapsed .user-item.initials .user-initials {
  font-size: var(--large);
}

header.collapsed .user-item.initials .user-initials + span {
  display: unset;
}

.user-item .dropdown {
  top: calc(100% + 0.97 * var(--header-margin));
}

.has-initials .user-item .dropdown {
  top: calc(100% + 0.47 * var(--header-margin));
}
</style>