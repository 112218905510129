<template>
  <li class="group-item" ref="item" :class="src ? 'with-avatar' : ''" @click="closeHeaderIfExpanded">
    <router-link v-if="to" :to="to" class="dex-button">
      <img v-if="src" :src="src" class="avatar">
      <!-- NOTE: Make sure image is available under the public folder and simply refer to it from the public folder's perspective -->
      <slot>This component expects an item label as a string in this slot.</slot>
    </router-link>
    <button v-else>
      <img v-if="src" :src="src" class="avatar">
      <slot></slot>
    </button>
  </li>
</template>

<script>
export default {
  name: "DexHeaderGroupItem",
  props: ["to", "src"],
  methods: {
    closeHeaderIfExpanded: function (ev) {
      ev.stopPropagation();
      document.querySelectorAll("header")[0].classList.remove("expanded");
      document
        .querySelectorAll("header .expanded")[0]
        .classList.remove("expanded");
    },
  },
};
</script>

<style scoped>
.group-item {
  cursor: pointer;
  color: var(--iss-blue);
  font-size: var(--smaller);
  font-weight: var(--bolder);
  text-transform: none;
  border-radius: 0;
  margin-top: var(--margin-025);
  margin-left: var(--margin-025);
  text-align: left;
  width: calc((100% - 2 * var(--margin-025)) / 2);
  list-style-type: none;
  display: inline-block;
  padding: 0;
}

.group-item > * {
  padding: var(--margin-05);
  z-index: 5;
  background:  rgba(229, 242, 255, 0.35);
  border-radius: 0 !important;
  /*transition: background-color ease var(--quick);*/
  width: 100%;
}

@media (hover:hover) and (pointer:fine) {
  .group-item > *:hover {
    background: var(--baby-blue);
  }
}

.group-item > *::after {
  content: none;
}

.group-item > *::after {
  border-radius: 0 !important;
}

.group-item a:hover,
.group-item a:active {
  color: var(--iss-blue) !important;
}

.group-item.with-avatar > * {
    padding: var(--margin-025);
}

.group-item.with-avatar * {
    vertical-align: middle;
    display: inline-block;
}

.avatar {
    width: calc(2.75 * var(--medium)) !important;
    height: calc(2.75 * var(--medium)) !important;
    border-radius: 50%;
    background: var(--light-grey) !important;
    margin-right: var(--margin-025);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.collapsed .avatar {
    margin-left: var(--margin-025);
}

.collapsed .expanded .dropdown .group-item {
  width: 100%;
  font-size: var(--large);
  font-weight: var(--bolder);
  width: 100%;
  margin-left: 0;
  margin-top: var(--side-margin-1);
}

.collapsed .user-item.expanded .dropdown .group-item > * {
  padding: var(--margin-075);
}

.collapsed .expanded .dropdown .group-item > * {
  padding: var(--margin-075);
}
</style>