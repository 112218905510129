<template>
    <div class="popup-window" @click="closePopupHandler" ref="bg" :class="{ 'cover-window': coverWindow, 'clear-background': clearBackground, 'loader': loader }">
        <div class="modal" :style="modalStyle" :class="{'hide-close': hideCloseButton }">
            <slot v-if="!loader"></slot>
            <img v-else src="loading.gif" />
            <button v-if="showCloseButton" class="dexcon-close" ref="close"></button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PopupWindow",
        props: ["canBeToggled", "hideCloseButton", "coverWindow", "minWidth", "clearBackground", "loader"],
        computed: {
            modalStyle: function () {
                if (!this.minWidth) return "";
                var style = "";
                if (this.minWidth > 100) style += "min-width: 100%;";
                else if (this.minWidth > 0) style += `min-width: ${this.minWidth}%;`;
                return style;
            },
            showCloseButton: function () {
                if (this.canBeToggled == false) return false;
                return !this.hideCloseButton;
            }
        },
        methods: {
            closePopupHandler: function (ev) {
                if (this.canBeToggled == false) return;
                if (ev.target == this.$refs.bg || ev.target == this.$refs.close) this.$emit("closePopup");
            }
        }
    }
</script>

<style scoped>
    .popup-window {
        position: absolute;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        z-index: 1;
    }

        .popup-window.cover-window {
            position: fixed !important;
            top: 0;
            left: 0;
            padding: 0 !important;
            z-index: 5;
        }

        .popup-window.cover-window .modal {
            max-width: calc(var(--max-width) - 2 * var(--side-margin-2));
        }

        .popup-window::before {
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: var(--white);
            opacity: 0.8;
        }

        .popup-window.clear-background::before {
            background: none;
        }

        .popup-window.loader {
            cursor: wait !important;
        }

    .modal {
        background: var(--white);
        box-shadow: var(--shadow-soft);
        max-height: 80%;
        margin: var(--side-margin-1);
        padding: var(--side-margin-1);
        overflow: auto;
        animation: float-up ease var(--quick) forwards;
        -webkit-font-smoothing: antialiased;
        text-align: left;
        position: relative;
    }

    @keyframes float-up {
        0% {
            transform: translate(0, 5%);
            opacity: 0;
        }

        100% {
            transform: none;
            opacity: 1;
        }
    }

    .modal > .dexcon-close:last-child {
        display: block;
        position: absolute;
        right: var(--margin-025);
        top: var(--margin-025);
    }

    .modal:not(.hide-close) > :nth-last-child(2) {
        margin-bottom: 0;
    }

    .button-wrapper {
        text-align: right;
        margin-left: calc(-1 * var(--margin-025));
        margin-top: calc(-1 * var(--margin-05));
    }

        .button-wrapper > button {
            margin-left: var(--margin-025);
            margin-top: var(--margin-05);
        }

            .button-wrapper > button:first-child {
                margin-left: 0;
            }

    .modal > :first-child:not(img, div) {
        display: block;
        padding-right: var(--margin-1);
    }

    .row {
        margin-bottom: var(--margin-05);
        position: relative;
    }

        .row.wrapper {
            margin-left: calc(-1 * var(--margin-05));
            margin-top: calc(-1 * var(--margin-05));
        }

            .row.wrapper > div {
                display: inline-block !important;
                margin-left: var(--margin-05);
                margin-top: var(--margin-05);
            }

            .row.wrapper input {
                width: calc(10 * var(--medium));
            }

            .row.wrapper > div > p {
                margin-bottom: 0;
            }

        .row input {
            width: 100%;
        }

    .loader img {
        margin: var(--margin-025);
        margin-bottom: 0;
    }

    @media (hover:hover) and (pointer:fine) {
        .loader .modal {
            display: none;
        }
    }
</style>