<template>
    <div class="pad-child" align="center">
        <div v-if="hasClientRoles(['ditplanner'])">
            <h2>Planning</h2>
            <PlanningSelector :level="3" ref="childRef" />
            <br />
            <div>
                <h4>Asset Search</h4>
                <div>
                    <input v-model=searchstring /> <button @click="search" class="box">search</button>
                </div>
            </div>
            <br />
            <div>
                <div><label>Asset</label></div>
                <select v-model="selectedasset">
                    <option v-for="(asset,k) in assetlist" :key="k" :value=asset>{{asset.name}}</option>
                </select>
            </div>
            <br />
            <DeviceTypeSelector ref="childDTSRef" />
            <br />
            <button @click="plan" class="box">plan</button>
            <br />
            <div v-for="(result,k) in installationresult" :key="k">
                <label>{{result}}</label>
            </div>
        </div>
        <div v-else>
            <h2>401 Unauthorized</h2>
        </div>
    </div>
</template>

<script>
    //import axios from "axios"
    import { mapGetters, mapActions } from "vuex"
    import axios from "axios"
    import PlanningSelector from '../components/dit/PlanningSelector.vue'
    import DeviceTypeSelector from '../components/dit/DeviceTypeSelector.vue'

    export default {
        name: "DITPlanning",
        data() {
            return {
                searchstring: null,
                installationresult: [],
                assetlist: null,
                selectedasset:null
            }
        },
        components: {
            PlanningSelector,
            DeviceTypeSelector
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile",
                hasClientRoles: "hasClientRoles"
            })
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            search() {
                this.assetlist = null;
                this.replaceLoader({ loader: true });
                return axios.get("/api/dit/FindSpaceForPlanning",
                    {
                        params: { SearchString: this.searchstring },
                        headers: {
                            'ConnectionName': this.$store.state.connection.connectionstring,
                            'FloorID': this.$refs.childRef.selectedPlanningFloor.id
                        }
                    }).then(res => {
                        if (!res.data.status) {
                            throw res.data.message[0];
                        }
                        this.assetlist = res.data.data;
                    }).catch(e => {
                        alert(e.message);
                    }).finally(() => {
                        this.replaceLoader({ loader: false });
                    });
            },
            plan() {
                this.replaceLoader({ loader: true });
                var msg = {
                    'DeviceType': this.$refs.childDTSRef.selectedDeviceType.id,
                    'AssetID': this.selectedasset.id
                };
                return axios.post("/api/dit/AddPlannedInstallation",msg,
                    {
                        headers: {
                            'ConnectionName': this.$store.state.connection.connectionstring
                        }
                    }).then(res => {
                        if (!res.data.status) {
                            throw res.data.message[0];
                        }
                        switch (res.data.data) {
                            case "-1":
                                this.installationresult.push(`${this.$refs.childDTSRef.selectedDeviceType.name} mapped to ${this.selectedasset.name} failed`);
                                break;
                            case "1":
                                this.installationresult.push(`${this.$refs.childDTSRef.selectedDeviceType.name} mapped to ${this.selectedasset.name} was sucessful`);
                                break;
                            case "2":
                            default:
                                break;
                        }
                    }).catch(e => {
                        alert(e.message);
                    }).finally(() => {
                        this.replaceLoader({ loader: false });
                    });
            },
            init: function () {
                if (!this.getProfile) return;
            }
        },
        watch: {
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
</style>