import * as dexlib from "../../assets/js/dexlib";
import Vue from "vue";

var baseJson = {
    country: [{
        name: "Germany"
    }]
};

export default ({
    namespaced: true,
    state: {
        json: JSON.parse(JSON.stringify(baseJson))
    },
    getters: {
        getJsonRoot: function (state) {
            return state.json[Object.keys(state.json)[0]];
        },
        getFullNodePath: (state) => (nodeArray, options) => {
            if (nodeArray.length < 1) {
                if (options) {
                    if (options.returnString) return "";
                }
                return { path: new Array(), isLeafNode: false }
            }

            // Return an obj with: path: {n & nodePathIntString}, isLeafNode: 
            var jsonPointer = state.json[Object.keys(state.json)[0]];
            var a = new Array();
            var pathArray = new Array();
            var isLeafNode = false;
            for (var i = 0; i < nodeArray.length; i++) {
                pathArray.push(nodeArray[i]); // Push to path array
                jsonPointer = jsonPointer[nodeArray[i]];
                if (i % 2 == 0) {
                    if (options) {
                        if (options.returnString) {
                            a.push(jsonPointer.name ? jsonPointer.name : "[illegalName]");
                        }
                    } else {
                        // Push name to a
                        a.push({
                            name: jsonPointer.name ? jsonPointer.name : "[illegalName]",
                            nodePathIntString: pathArray.join("/")
                        });
                    }
                }
            }

            if (options) {
                // For descriptions
                if (options.returnString) return pathArray.join(` ${options.delimiter ? options.delimiter : "→"} `);
            } else {
                return { path: a, isLeafNode: isLeafNode };
            }
        },
        getNodeContent: (state) => (nodeArray) => {
            var o = new Object();
            try {
                if (nodeArray.length < 1) {
                    return o;
                }
                var jsonPointer = state.json[Object.keys(state.json)[0]];
                for (var i = 0; i < nodeArray.length; i++) {
                    jsonPointer = jsonPointer[nodeArray[i]];
                }
                o = jsonPointer;
            } catch {
                // Do nothing
            }
            return o;
        },
        getNodeType: (state) => (nodeArray) => {
            return nodeArray.length <= 1 ? Object.keys(state.json)[0] : nodeArray.slice(-2, -1).toString();
        },
        getPathForNewNode: (state) => (parentNodeArray, type) => {
            var jsonPointer = state.json[Object.keys(state.json)[0]];
            for (var i = 0; i < parentNodeArray.length; i++) {
                jsonPointer = jsonPointer[parentNodeArray[i]];
            }
            parentNodeArray.push(type);
            if (Object.keys(jsonPointer).indexOf(type) > -1) {
                parentNodeArray.push(jsonPointer[type].length);
            } else {
                parentNodeArray.push(0);
            }
            return parentNodeArray;
        }
    },
    mutations: {
        sortArray: function (state, payload) {
            if (!payload.nodeArray) throw "Payload is missing property, 'nodeArray'";
            var nodeArray = payload.nodeArray; 
            if (nodeArray.length < 1) return true;
            var jsonPointer = state.json[Object.keys(state.json)[0]];
            for (var i = 0; i < nodeArray.length; i++) {
                jsonPointer = jsonPointer[nodeArray[i]];
            }
            //// Sort by type, then name
            //if (jsonPointer) jsonPointer.sort((a, b) => {
            //    //if (a.t > b.t) return 1;
            //    //if (a.t < b.t) return -1;
            //    if (a.name > b.name) return 1;
            //    if (a.name < b.name) return -1;
            //});
            return true;
        },
        removeNode: function (state, payload) {
            if (!payload.nodeArray) throw "Payload is missing property, 'nodeArray'";
            var jsonPointer = state.json[Object.keys(state.json)[0]];
            for (var i = 0; i < payload.nodeArray.length - 1; i++) {
                jsonPointer = jsonPointer[payload.nodeArray[i]];
            }
            jsonPointer.splice(payload.nodeArray[i], 1);
            return true;
        },
        setNodeLeafProperties: function (state, payload) {
            if (!payload.nodeArray) throw "Payload is missing property, 'nodeArray'";
            if (!payload.propsArray) throw "Payload is missing property, 'propsArray'";
            var jsonPointer = state.json[Object.keys(state.json)[0]];
            for (var i = 0; i < payload.nodeArray.length; i++) {
                jsonPointer = jsonPointer[payload.nodeArray[i]];
            }
            // First we populate with payload's content
            for (i = 0; i < Object.keys(payload.propsArray).length; i++) {
                //if (payload.propsArray[Object.keys(payload.propsArray)[i]] != undefined) {
                    Vue.set(jsonPointer, Object.keys(payload.propsArray)[i], payload.propsArray[Object.keys(payload.propsArray)[i]]);
                //}
            }
        },
        setNewNode: function (state, payload) {
            if (!payload.nodeArray) throw "Payload is missing property, 'nodeArray'";
            var jsonPointer = state.json[Object.keys(state.json)[0]];
            for (var i = 0; i < payload.nodeArray.length - 1; i++) {
                if (jsonPointer[payload.nodeArray[i]]) {
                    jsonPointer = jsonPointer[payload.nodeArray[i]];
                } else {
                    Vue.set(jsonPointer, payload.nodeArray[i], new Array());
                    //jsonPointer[payload.nodeArray[i]] = new Array();
                    jsonPointer = jsonPointer[payload.nodeArray[i]];
                }
            }
            jsonPointer.push({
                name: "New Node"
            });
        },
        setJsonModel: function (state, payload) {
            if (!payload.model) throw "Payload is missing property, 'model'";
            switch (typeof (payload.model)) {
                case "string":
                    state.json = JSON.parse(payload.model);
                    break;
                case "object":
                    state.json = payload.model;
                    localStorage.setItem("model", JSON.stringify(state.json));
                    break;
            }
        }
    },
    actions: {
        sortChildren: function (context, payload) {
            try {
                context.commit("sortArray", payload);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        deleteNode: function (context, payload) {
            try {
                context.commit("removeNode", payload);
                localStorage.setItem("model", JSON.stringify(context.state.json));
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        replaceNodeProperties: function(context, payload) {
            try {
                context.commit("setNodeLeafProperties", payload);
                context.commit("sortArray", { nodeArray: payload.nodeArray.slice(0, -1) });
                localStorage.setItem("model", JSON.stringify(context.state.json));
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        createNode: function (context, payload) {
            try {
                context.commit("setNewNode", payload);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        replaceJsonModel: function (context, payload) {
            try {
                context.commit("setJsonModel", payload);
            } catch (e) {
                dexlib.vuewarn(e);
            }
        },
        resetJsonModel: function (context) {
            try {
                context.commit("setJsonModel", {  model: JSON.parse(JSON.stringify(baseJson)) });
            } catch (e) {
                dexlib.vuewarn(e);
            }
        }
    }
})