<template>
    <div>
        <input id="collapsible" class="toggle" type="checkbox"> <label for="collapsible" class="lbl-toggle bg-azure-blue box">Spatial Selector</label>
        <div class="collapsible-content">
            <ConnectionSelector @connectionChangedEvent="onConnectionNameChange()" />
            <div v-if="level >=2">
                <div><label>Building</label></div>
                <select v-model="selectedPlanningBuilding" v-on:change="onBuildingChnage($event)">
                    <option v-for="(building) in planningBuildingList" :key="building.id" :value=building>{{building.name}}</option>
                </select>
            </div>
            <div v-if="level>=3">
                <div><label>Floor</label></div>
                <select v-model="selectedPlanningFloor" v-on:change="onBuildingChnage($event)">
                    <option v-for="(building) in planningFloorList" :key="building.id" :value=building>{{building.name}}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios"
    import { mapGetters, mapActions } from "vuex"
    import ConnectionSelector from '../default/ConnectionSelector.vue'

    export default {
        name: "PlanningSelector",
        props: {
            level: {
                type: Number,
                required: true,
            }
        },
        data() {
            return {
                display_div: true
            }
        },
        components: {
            ConnectionSelector
        },
        emits: ['connectionChangedEvent', 'buildingChangedEvent'],
        computed: {

            selectedPlanningBuilding: {
                get() {
                    return this.$store.state.dit.selectedPlanningBuilding
                },
                set(value) {
                    this.$store.commit('dit/setSelectedPlanningBuilding', value)
                }
            },
            planningBuildingList: {
                get() {
                    return this.$store.state.dit.planningBuildingList
                },
                set(value) {
                    this.$store.commit('dit/setPlanningBuildingList', value)
                }
            },
            selectedPlanningFloor: {
                get() {
                    return this.$store.state.dit.selectedPlanningFloor
                },
                set(value) {
                    this.$store.commit('dit/setSelectedPlanningFloor', value)
                }
            },
            planningFloorList: {
                get() {
                    return this.$store.state.dit.planningFloorList
                },
                set(value) {
                    this.$store.commit('dit/setPlanningFloorList', value)
                }
            },
            connection: {
                get() {
                    return this.$store.state.connection.connectionstring
                }
            },
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile",
                hasClientRoles: "hasClientRoles"
            })
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            LoadBuildings() {
                if (this.connection !== "") {
                    this.replaceLoader({ loader: true });
                    return axios.get("/api/dit/GetAllPlanningBuildings",
                        {
                            headers: {
                                'ConnectionName': this.connection
                            }
                        }).then(res => {
                            if (!res.data.status) {
                                throw res.data.message[0];
                            }
                            this.planningBuildingList = res.data.data;
                        }).catch(e => {
                            alert(e.message);
                        }).finally(() => {
                            this.replaceLoader({ loader: false });
                        });
                }
            },
            LoadFloors() {
                this.replaceLoader({ loader: true });
                return axios.get("/api/dit/GetAllPlanningFloors",
                    {
                        headers: {
                            'ConnectionName': this.connection,
                            'BuildingID': this.selectedPlanningBuilding.id
                        }
                    }).then(res => {
                        if (!res.data.status) {
                            throw res.data.message[0];
                        }
                        this.planningFloorList = res.data.data;
                    }).catch(e => {
                        alert(e.message);
                    }).finally(() => {
                        this.replaceLoader({ loader: false });
                    });
            },
            onBuildingChnage() {
                if (!this.getProfile) return;
                this.planningFloorList = null;
                this.selectedPlanningFloor = null;
                this.LoadFloors();
                this.$emit('buildingChangedEvent', this.selectedPlanningBuilding);
                return true;
            },
            onConnectionNameChange() {
                this.selectedPlanningBuilding = null;
                this.LoadBuildings();
                this.$emit('connectionChangedEvent');
                return true;                
            },
            toggleDiv: function () {
                this.display_div = !this.display_div;
                return true;
            },
            init: function () {
                if (!this.getProfile) return;
                if (this.connection !== null && this.connection !== "") {
                    console.log(this.connection);
                    this.onConnectionNameChange()
                }
                return true;                
            }
        },
        watch: {
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    input[type="checkbox"] {
        display: none;
    }

    .lbl-toggle {
        min-width: calc(10 * var(--smaller));
        max-width: calc(13 * var(--smaller));
        text-align: center;
        cursor: pointer;
        transition: all 0.25s ease-out;
        background: var(--azure-blue);
        border-radius: 0;
        border: 1px solid var(--azure-blue);
        color: var(--stay-white);
        padding: calc(1.25 * var(--margin-025)) calc(0.75 * var(--margin-1));
    }

        .lbl-toggle:hover {
            color: #fff;
        }

        .lbl-toggle::before {
            content: " ";
            display: inline-block;
            border-top: 5px solid transparent;
            border-bottom: 5px solid transparent;
            border-left: 5px solid currentColor;
            vertical-align: middle;
            margin-right: 0.7rem;
            transform: translateY(-2px);
            transition: transform 0.2s ease-out;
        }

    .toggle:checked + .lbl-toggle::before {
        transform: rotate(90deg) translateX(-3px);
    }

    .collapsible-content {
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.25s ease-in-out;
    }

    .toggle:checked + .lbl-toggle + .collapsible-content {
        max-height: 350px;
    }

    .toggle:checked + .lbl-toggle {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    .collapsible-content p {
        margin-bottom: 0;
    }
</style>