<template>
    <div class="pad-child" align="center">
        <div v-if="hasClientRoles(['ditplanner'])">
            <h2>Sensor Onboarding</h2>
            <PlanningSelector :level="1" ref="childRef" />
            <div style="border: 1px solid lightgray; margin-bottom: 1rem;">
                <DeviceTypeSelector ref="childDTSRef" />
                <br />
                <div>
                    <h4>Device EUI</h4>
                    <input v-model=eui />
                    <div>
                        <br />
                        <button @click="onboarding" class="box">onboarding</button>
                    </div>
                    <br />
                    <div v-for="(result,k) in installationresult" :key="k">
                        <label>{{result}}</label>
                    </div>
                </div>
            </div>
            <div style="border: 1px solid lightgray;">
                <div>
                    <h4>File Upload</h4>
                </div>
                <br />
                <div class="help-tip">
                    <p>
                        Template<br />
                        <a href="/Template.csv" target="_blank">download</a>
                    </p>
                </div><br />
                <input class="file-input" type="file" id="spatial-model-xml" accept=".csv" ref="file" />
                <br />
                <br />
                <button @click="uploadFile" class="box">upload</button>
                <br />
                <br />
               <div v-for="(result,k) in uploadresult" :key="k">
                    <label>{{result}}</label>
                </div>
                <br />
            </div>
        </div>
        <div v-else>
            <h2>401 Unauthorized</h2>
        </div>
    </div>
</template>

<script>
    //import axios from "axios"
    import { mapGetters, mapActions } from "vuex"
    import axios from "axios"
    import PlanningSelector from '../components/dit/PlanningSelector.vue'
    import DeviceTypeSelector from '../components/dit/DeviceTypeSelector.vue'
    export default {
        name: "DITSensorOnboarding",
        data() {
            return {
                eui: null,
                installationresult: [],
                uploadresult: []
            }
        },
        components: {
            PlanningSelector,
            DeviceTypeSelector
        },
        computed: {
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile",
                hasClientRoles: "hasClientRoles"
            })
        },
        methods: {
            ...mapActions("loader", {
                replaceLoader: "replaceLoader"
            }),
            onboarding() {
                if (this.eui != null && this.eui != "" && this.$refs.childDTSRef.selectedDeviceType != null && this.$refs.childDTSRef.selectedDeviceType != "") {
                    this.replaceLoader({ loader: true });
                    return axios.post("/api/dit/CreateNewDBDevice", "",
                        {
                            headers: {
                                'ConnectionName': this.$store.state.connection.connectionstring,
                                'DeviceEUI': this.eui,
                                'DeviceType': this.$refs.childDTSRef.selectedDeviceType.id
                            }
                        }).then(res => {
                            if (!res.data.status) {
                                throw res.data.message[0];
                            }
                            switch (res.data.data) {
                                case "-1":
                                    this.installationresult.push(`${this.eui} onboarding failed`);
                                    break;
                                case "1":
                                    this.installationresult.push(`${this.eui} onboarded sucessful`);
                                    break;
                                case "2":
                                    this.installationresult.push(`${this.eui} already existed`);
                                    break;
                                default:
                                    break;
                            }
                        }).catch(e => {
                            alert(e.message);
                        }).finally(() => {
                            this.replaceLoader({ loader: false });
                            this.eui = null;
                        });
                }

            },
            uploadFile: function () {
                var formData = new FormData();
                formData.append("file", this.$refs.file.files[0]);
                formData.append('ConnectionName', this.$store.state.connection.connectionstring);
                this.replaceLoader({ loader: true });
                return axios.post("api/dit/UploadFile", formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => {
                    this.uploadresult = res.data.data;
                    if (!res.data.status) {
                        throw res.data.message[0];
                    }
                }).catch((e) => {
                        alert(e.message);
                }).finally(() => {
                    this.$refs.file.value = null;
                    this.fileIsSelected = false;
                    this.replaceLoader({ loader: false });
                });
            },
            init: function () {
                if (!this.getProfile) return;
            }
        },
        watch: {
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
    /* The icon */
    .help-tip {
        /*position: absolute;*/
      /*  top: 50%;
        left: 50%;*/
        /*transform: translate(-50%, -50%);*/
        margin: auto;
        text-align: center;
        border: 2px solid #444;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        font-size: 12px;
        line-height: 12px;
        cursor: default;
    }

        .help-tip:before {
            content: '?';
            font-family: sans-serif;
            font-weight: normal;
            color: #444;
        }

        .help-tip:hover p {
            display: block;
            transform-origin: 100% 0%;
            -webkit-animation: fadeIn 0.3s ease;
            animation: fadeIn 0.3s ease;
        }

        /* The tooltip */
        .help-tip p {
            display: none;
            font-family: sans-serif;
            text-rendering: optimizeLegibility;
            -webkit-font-smoothing: antialiased;
            text-align: center;
            background-color: #FFFFFF;
            padding: 12px 16px;
            width: 178px;
            height: auto;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 5%);
            border-radius: 3px;
            /* 	border: 1px solid #E0E0E0; */
            box-shadow: 0 0px 20px 0 rgba(0,0,0,0.1);
            color: #37393D;
            font-size: 12px;
            line-height: 18px;
            z-index: 99;
        }

            .help-tip p a {
                color: #067df7;
                text-decoration: none;
            }

                .help-tip p a:hover {
                    text-decoration: underline;
                }

            /* The pointer of the tooltip */
            .help-tip p:before {
                position: absolute;
                content: '';
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-bottom-color: #FFFFFF;
                top: -9px;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            /* Prevents the tooltip from being hidden */
            .help-tip p:after {
                width: 10px;
                height: 40px;
                content: '';
                position: absolute;
                top: -40px;
                left: 0;
            }

    /* CSS animation */
    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 100%;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 100%;
        }
    }
</style>