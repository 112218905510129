<template>
    <div>
        <div><label>Connection Name</label></div>
        <select v-model="connection" v-on:change="onConnectionNameChange($event)">
            <option v-for="(connectionNames, k) in connectionNames" :key="k">{{ connectionNames }}</option>
        </select>
    </div>
</template>

<script>
    import axios from "axios"
    import { mapGetters } from "vuex"

    export default {
        name: "ConnectionSelector",
        data() {
            return {
                connectionNames: [],
            }
        },
        emits: ['connectionChangedEvent'],
        computed: {
            connection: {
                get() {
                    return this.$store.state.connection.connectionstring
                },
                set(value) {
                    this.$store.commit('connection/setConnection', value)
                }
            },
            ...mapGetters("lang", {
                getText: "getText"
            }),
            ...mapGetters("keycloak", {
                getProfile: "getProfile",
                hasClientRoles: "hasClientRoles"
            })
        },
        methods: {
            tryLoadConnectionNames: function () {
                axios.get("api/dit/list").then(res => {
                    if (res.data.status) {
                        this.connectionNames = res.data.flexData;
                        this.connectionNames.unshift(" ")
                    } else {
                        throw res.data.message;
                    }
                }).catch(e => {
                    alert(e);
                });
            },
            onConnectionNameChange() {
                this.$emit('connectionChangedEvent');
            },
            init: function () {
                if (!this.getProfile) return;
                this.tryLoadConnectionNames();
            }
        },
        watch: {
            getProfile: function () {
                this.init();
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style scoped>
</style>