var statesInGermany = [
    "Baden-Württemberg",
    "Bayern",
    "Berlin",
    "Brandenburg",
    "Bremen",
    "Hamburg",
    "Hessen",
    "Niedersachsen",
    "Mecklenburg-Vorpommern",
    "Nordrhein-Westfalen (NRW)",
    "Rheinland-Pfalz",
    "Saarland",
    "Sachsen",
    "Sachsen-Anhalt",
    "Schleswig-Holstein",
    "Thüringen"
];

var fmsSpaceClassification = [
    "Atrium",
    "Canteen",
    "Closed Office",
    "Corridor",
    "Disabled Washroom (Cubicle)",
    "Disabled Washroom (General)",
    "Female Washroom (Cubicle)",
    "Female Washroom (General)",
    "Female Washroom (Generic)",
    "Health & Fitness",
    "Hot Desk-Touchdown Area",
    "Kitchen Area",
    "Male Washroom (Cubicle)",
    "Male Washroom (General)",
    "Male Washroom (Generic)",
    "Meeting Room",
    "Meeting Room-Open Office Area",
    "Meeting Room-Section",
    "Men Washroom (Cubicle)",
    "Men Washroom (General)",
    "Open Office Area",
    "Reception",
    "Reception-Reception/Entrance",
    "Reception/Entrance",
    "Room-Health & Fitness",
    "Senion Beacon",
    "Stairway",
    "Technical/Material Room",
    "Technical/Material Room-Technical/Material Room",
    "Touchdown Area",
    "Workplace Area-Atrium",
    "Workplace Area-Closed Office",
    "Workplace Area-Corridor",
    "Workplace Area-Kitchen Area",
    "Workplace Area-Meeting Room",
    "Workplace Area-Open Office Area",
    "Workplace Area-Section",
    "Workplace Area-Touchdown Area",
    "Zone-Atrium",
    "Zone-Corridor",
    "Zone-Meeting Room",
    "Zone-Open Office Area",
    "Zone-Reception/Entrance",
    "Zone-Section",
    "Zone-Stairway"
];

export default ({
    namespaced: true,
    state: {
        rootNodeType: "country",
        treeOpenByDefault: [
            "country",
            "state",
            "city",
            "property"
        ],
        supportedObjectProps: {
            country: ["state"],
            state: ["city"],
            city: ["property"],
            property: ["building"],
            building: ["floor"],
            floor: ["space"],
            //section: ["space"],
            space: ["subspace"]
        },
        supportedProps: {
            country: [
                { type: "name", required: true, nonModifiable: true }
            ],
            state: [
                { type: "name", required: true, input: "dropdown", inputSelection: statesInGermany }
            ],
            city: [
                { type: "name", required: true }
            ],
            property: [
                { type: "name", required: true }
            ],
            building: [
                { type: "name", required: true }
            ],
            floor: [
                { type: "name", required: true },
                { type: "floorLevel", input: "number", required: true, showInPreview: true, def: "def-floor-level" }
            ],
            section: [
                { type: "name", required: true }
            ],
            space: [
                { type: "name", required: true },
                { type: "description", showInPreview: true,  required: true},
                { type: "fmsSpaceClassification", input: "dropdown", inputSelection: fmsSpaceClassification }
            ],
            subspace: [
                { type: "name", required: true },
                { type: "description", input: "textarea", showInPreview: true, required: true},
                { type: "fmsSpaceClassification", input: "dropdown",inputSelection: fmsSpaceClassification }
            ]
        }
    },
    getters: {
        getTreeDefaultIsExpandedList: function (state) {
            return state.treeOpenByDefault;
        },
        getRootNodeType: function (state) {
            return state.rootNodeType;
        },
        getSupportedProps: function (state) {
            return state.supportedProps;
        },
        getSupportedObjectProps: function (state) {
            return state.supportedObjectProps;
        }
    }
})